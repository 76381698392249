import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { classNames } from 'primereact/utils';
import { Route, useHistory } from 'react-router-dom';

import AppTopbar from './AppTopbar';
import AppFooter from './AppFooter';
import AppConfig from './AppConfig';
import AppMenu from './AppMenu';
import AppInlineProfile from './AppInlineProfile';

import PrimeReact from 'primereact/api';
import { locale, addLocale } from 'primereact/api';
import translations from './i18n/locales/pl/primereact-translations.json';

import { AxiosConfigContainer } from './components/AxiosConfig';
import { UserManagement } from './components/users/UserManagement';
import { RoleManagement } from './components/roles/RoleManagement';
import { useTranslation } from 'react-i18next';
import { hasPermission, hasOneOfPermissions } from './components/_shared/hasPermission';
import ChangePasswordDialog from './components/ChangePassword';
import { userActions } from './store/user-slice';
import usersService from './service/api/UsersService';
//
import { ClientManagement } from './components/client/ClientManagement';
//
import { DivisionManagement } from './components/division/DivisionManagement';
//
import { PropertyManagement } from './components/property/PropertyManagement';
//
import { ResidentCardManagement } from './components/residentCard/ResidentCardManagement';
//
import { WasteGroupManagement } from './components/wasteGroup/WasteGroupManagement';
//
import { WasteTypeManagement } from './components/wasteType/WasteTypeManagement';
//
import { OpenReceptionListPage } from './components/wasteReception/OpenReceptionListPage';
import { ClosedReceptionListPage } from './components/wasteReception/ClosedReceptionListPage';
//
import { PropertyDetailsPage } from './components/property/PropertyDetailsPage';
//
import { ArrearAddressManagement } from './components/arrearAddress/ArrearAddressManagement';
//
import { DeclarationAddressManagement } from './components/declarationAddress/DeclarationAddressManagement';
// <%-appJsImport%>

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import { Dashboard } from './components/dashboard/Dashboard';

const App = () => {
  const [menuActive, setMenuActive] = useState(false);
  const [menuMode, setMenuMode] = useState('static');
  const [darkMenu, setDarkMenu] = useState(true);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [topbarMenuActive, setTopbarMenuActive] = useState(false);
  const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
  const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
  const [activeTopbarItem, setActiveTopbarItem] = useState(null);
  const [inlineMenuActive, setInlineMenuActive] = useState(false);
  const [profileMode, setProfileMode] = useState('popup');
  const [configActive, setConfigActive] = useState(false);
  const [inputStyle, setInputStyle] = useState('outlined');
  const [ripple, setRipple] = useState(false);

  const history = useHistory();
  const [initialized, setInitialized] = useState(false);

  const [changePasswordDialogVisible, setChangePasswordDialogVisible] = useState(false);
  const dispatch = useDispatch();
  const loggedUserContext = useSelector((state: any) => state.user.context);

  const { i18n, t } = useTranslation();
  addLocale('pl', translations);

  const fetchUserContext = useCallback(() => {
    Promise.all([usersService.getLoggedUserContext()]).then((response) => {
      dispatch(userActions.setLoggedUserContext(response[0].data));
      const language = response[0].data.language;
      i18n.changeLanguage(language);

      locale(language);

      setInitialized(true);
    });
  }, [dispatch, i18n]);

  useEffect(() => {
    fetchUserContext();
  }, [fetchUserContext]);

  let menuClick = false;
  let configClick = false;
  let topbarItemClick = false;
  let inlineMenuClick = false;

  const menu = [
    {
      icon: 'pi pi-fw pi-home',
      items: [
        {
          label: t('common.pszok'),
          icon: 'pi pi-fw pi-home',
          to: '/dashboard',
        },
      ],
    },
    {
      label: t('common.admin'),
      icon: 'pi pi-align-left',
      visible: hasOneOfPermissions(['USER_MANAGEMENT', 'ROLE_MANAGEMENT'], loggedUserContext.permissions),
      items: [
        {
          label: t('users.header'),
          icon: 'pi pi-align-left',
          to: '/users',
          visible: hasPermission('USER_MANAGEMENT', loggedUserContext.permissions),
        },
        {
          label: t('roles.header'),
          icon: 'pi pi-align-left',
          to: '/roles',
          visible: hasPermission('ROLE_MANAGEMENT', loggedUserContext.permissions),
        },
        //
        {
          label: t('clients.header'),
          icon: 'pi pi-fw pi-eye',
          to: '/client',
          visible: hasPermission('CLIENT_MANAGEMENT', loggedUserContext.permissions),
        },
        //
        {
          label: t('divisions.header'),
          icon: 'pi pi-fw pi-eye',
          to: '/division',
          visible: hasPermission('DIVISION_MANAGEMENT', loggedUserContext.permissions),
        },
      ],
    },
    {
      label: t('common.configuration'),
      icon: 'pi pi-align-left',
      visible: true,
      items: [
        {
          label: t('properties.header'),
          icon: 'pi pi-fw pi-eye',
          to: '/property',
          visible: hasPermission('PROPERTY_MANAGEMENT', loggedUserContext.permissions),
        },
        //
        {
          label: t('residentCards.header'),
          icon: 'pi pi-fw pi-eye',
          to: '/resident-card',
          visible: hasPermission('RESIDENT_CARD_MANAGEMENT', loggedUserContext.permissions),
        },
        //
        {
          label: t('wasteGroups.header'),
          icon: 'pi pi-fw pi-eye',
          to: '/waste-group',
          visible: hasPermission('WASTE_GROUP_MANAGEMENT', loggedUserContext.permissions),
        },
        //
        {
          label: t('wasteTypes.header'),
          icon: 'pi pi-fw pi-eye',
          to: '/waste-type',
          visible: hasPermission('WASTE_TYPE_MANAGEMENT', loggedUserContext.permissions),
        },
        //
        {
          label: t('wasteReceptions.open_receptions'),
          icon: 'pi pi-fw pi-eye',
          to: '/waste-reception',
          visible: hasPermission('WASTE_RECEPTION_MANAGEMENT', loggedUserContext.permissions),
        },
        {
          label: t('wasteReceptions.closed_receptions'),
          icon: 'pi pi-fw pi-eye',
          to: '/closed-reception',
          visible: hasPermission('WASTE_RECEPTION_MANAGEMENT', loggedUserContext.permissions),
        },
        //
        {
          label: t('arrearAddresss.header'),
          icon: 'pi pi-fw pi-eye',
          to: '/arrear-address',
          visible: hasPermission('ARREAR_ADDRESS_MANAGEMENT', loggedUserContext.permissions),
        },
        //
        {
          label: t('declarationAddresss.header'),
          icon: 'pi pi-fw pi-eye',
          to: '/declaration-address',
          visible: hasPermission('DECLARATION_ADDRESS_MANAGEMENT', loggedUserContext.permissions),
        },
        // <%-appJsMenu%>
      ],
    },
  ];

  const routers = [
    { path: '/dashboard', exact: false, component: Dashboard },
    { path: '/users', exact: false, component: UserManagement },
    { path: '/roles', exact: false, component: RoleManagement },
    //
    {
      path: '/client',
      component: ClientManagement,
    },
    //
    {
      path: '/division',
      component: DivisionManagement,
    },
    //
    {
      path: '/property',
      component: PropertyManagement,
    },
    //
    {
      path: '/resident-card',
      component: ResidentCardManagement,
    },
    //
    {
      path: '/waste-group',
      component: WasteGroupManagement,
    },
    //
    {
      path: '/waste-type',
      component: WasteTypeManagement,
    },
    //
    {
      path: '/waste-reception',
      component: OpenReceptionListPage,
    },
    {
      path: '/closed-reception',
      component: ClosedReceptionListPage,
    },
    //
    {
      path: '/property-details/:id',
      component: PropertyDetailsPage,
    },
    //
    {
      path: '/arrear-address',
      component: ArrearAddressManagement,
      render: () => {
        return <ArrearAddressManagement />;
      },
    },
    //
    {
      path: '/declaration-address',
      component: DeclarationAddressManagement,
      render: () => {
        return <DeclarationAddressManagement />;
      },
    },
    // <%-appJsRouters%>
  ];

  const showChangePasswordDialog = () => {
    setChangePasswordDialogVisible(true);
  };

  const hideChangePasswordDialog = () => {
    setChangePasswordDialogVisible(false);
  };

  const onInputStyleChange = (inputStyle) => {
    setInputStyle(inputStyle);
  };

  const onRippleChange = (e) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onMenuModeChange = (e) => {
    setMenuMode(e.value);
    setStaticMenuDesktopInactive(false);
    setOverlayMenuActive(false);

    if (e.value === 'horizontal') {
      setProfileMode('popup');
    }
  };

  const onMenuColorChange = (e) => {
    setDarkMenu(e.value);
  };

  const onProfileChange = (e) => {
    setProfileMode(e.value);
  };

  const onDocumentClick = () => {
    if (!topbarItemClick) {
      setActiveTopbarItem(null);
      setTopbarMenuActive(false);
    }

    if (!menuClick) {
      if (isHorizontal() || isSlim()) {
        setMenuActive(false);
      }
      hideOverlayMenu();
    }

    if (!inlineMenuClick && profileMode === 'inline' && isSlim() && !isMobile()) {
      setInlineMenuActive(false);
    }

    if (configActive && !configClick) {
      setConfigActive(false);
    }

    inlineMenuClick = false;
    configClick = false;
    topbarItemClick = false;
    menuClick = false;
  };

  const onMenuitemClick = (event) => {
    if (!event.item.items) {
      hideOverlayMenu();

      if (isSlim() || isHorizontal()) {
        setMenuActive(false);
      }
    }
  };

  const onRootMenuitemClick = () => {
    setMenuActive((prevMenuActive) => !prevMenuActive);
  };

  const onMenuClick = () => {
    menuClick = true;

    if (inlineMenuActive && !inlineMenuClick) {
      setInlineMenuActive(false);
    }
  };

  const isMenuVisible = () => {
    if (isDesktop()) {
      if (menuMode === 'static') return !staticMenuDesktopInactive;
      else if (menuMode === 'overlay') return overlayMenuActive;
      else return true;
    } else {
      return true;
    }
  };

  const onMenuButtonClick = (event) => {
    menuClick = true;
    setTopbarMenuActive(false);

    if (isOverlay() && !isMobile()) {
      setOverlayMenuActive((prevOverlayMenuActive) => !prevOverlayMenuActive);
    } else {
      if (isDesktop()) {
        setStaticMenuDesktopInactive((prevStaticMenuDesktopInactive) => !prevStaticMenuDesktopInactive);
      } else {
        setStaticMenuMobileActive((prevStaticMenuMobileActive) => !prevStaticMenuMobileActive);
      }
    }

    event.preventDefault();
  };

  const onProfileButtonClick = () => {
    setInlineMenuActive((prevInlineMenuActive) => !prevInlineMenuActive);
    inlineMenuClick = true;

    if (isSlim() || isHorizontal()) {
      setMenuActive(false);
    }
  };

  const onTopbarMenuButtonClick = (event: any) => {
    topbarItemClick = true;
    setTopbarMenuActive((prevTopbarMenuActive) => !prevTopbarMenuActive);

    hideOverlayMenu();

    event.preventDefault();
  };

  const onTopbarItemClick = (event, item) => {
    topbarItemClick = true;

    if (activeTopbarItem === item) {
      setActiveTopbarItem(null);
    } else {
      setActiveTopbarItem(item);
    }

    event.preventDefault();
  };

  const onConfigClick = () => {
    configClick = true;
  };

  const onConfigButtonClick = () => {
    setConfigActive((prevConfigActive) => !prevConfigActive);
    configClick = true;
  };

  const hideOverlayMenu = () => {
    setOverlayMenuActive(false);
    setStaticMenuMobileActive(false);
  };

  const isDesktop = () => {
    return window.innerWidth > 896;
  };

  const isMobile = () => {
    return window.innerWidth <= 896;
  };

  const isOverlay = () => {
    return menuMode === 'overlay';
  };

  const isHorizontal = () => {
    return menuMode === 'horizontal';
  };

  const isSlim = () => {
    return menuMode === 'slim';
  };

  const isStatic = () => {
    return menuMode === 'static';
  };

  const hasInlineProfile = profileMode === 'inline' && !isHorizontal();

  const containerClassName = classNames('layout-wrapper', {
    'layout-static': isStatic(),
    'layout-overlay': isOverlay(),
    'layout-overlay-active': overlayMenuActive,
    'layout-horizontal': isHorizontal(),
    'layout-slim': isSlim(),
    'layout-static-inactive': staticMenuDesktopInactive,
    'layout-mobile-active': staticMenuMobileActive,
    'layout-menu-dark': darkMenu,
    'layout-menu-light': !darkMenu,
    'p-input-filled': inputStyle === 'filled',
    'p-ripple-disabled': !ripple,
  });

  const menuContainerClassName = classNames('layout-menu-container', {
    'layout-menu-container-inactive': !isMenuVisible(),
  });

  return (
    <div className={containerClassName} onClick={onDocumentClick}>
      <AxiosConfigContainer />
      {initialized && (
        <React.Fragment>
          <AppTopbar
            topbarMenuActive={topbarMenuActive}
            activeTopbarItem={activeTopbarItem}
            onMenuButtonClick={onMenuButtonClick}
            onTopbarMenuButtonClick={onTopbarMenuButtonClick}
            onTopbarItemClick={onTopbarItemClick}
            isHorizontal={isHorizontal()}
            profileMode={profileMode}
            isMobile={isMobile}
            onChangePasswordClick={showChangePasswordDialog}
          />

          <div className={menuContainerClassName} onClick={onMenuClick}>
            <div className="layout-menu-logo">
              <button className="p-link" onClick={() => history.push('/')}>
                <img id="layout-menu-logo" src="assets/layout/images/logo-white.png" alt="babylon-logo" />
              </button>
            </div>
            <div className="layout-menu-wrapper">
              <div className="menu-scroll-content">
                {hasInlineProfile && (
                  <AppInlineProfile inlineMenuActive={inlineMenuActive} onProfileButtonClick={onProfileButtonClick} />
                )}
                <AppMenu
                  model={menu}
                  menuMode={menuMode}
                  active={menuActive}
                  onMenuitemClick={onMenuitemClick}
                  onRootMenuitemClick={onRootMenuitemClick}
                />
              </div>
            </div>
          </div>

          <div className="layout-main">
            <div className="layout-content">
              {routers.map((router, index) => {
                if (router.exact) {
                  return <Route key={`router${index}`} path={router.path} exact component={router.component} />;
                }

                return <Route key={`router${index}`} path={router.path} component={router.component} />;
              })}
            </div>

            <ChangePasswordDialog
              dialogVisible={changePasswordDialogVisible}
              closeAddEditModal={hideChangePasswordDialog}
            />
            <AppFooter />
          </div>

          <AppConfig
            configActive={configActive}
            menuMode={menuMode}
            onMenuModeChange={onMenuModeChange}
            isDarkMenu={darkMenu}
            onMenuColorChange={onMenuColorChange}
            profileMode={profileMode}
            onProfileChange={onProfileChange}
            onConfigClick={onConfigClick}
            onConfigButtonClick={onConfigButtonClick}
            rippleActive={ripple}
            onRippleChange={onRippleChange}
            inputStyle={inputStyle}
            onInputStyleChange={onInputStyleChange}
          ></AppConfig>

          {staticMenuMobileActive && <div className="layout-mask"></div>}
        </React.Fragment>
      )}
    </div>
  );
};

export default App;

import axios from 'axios';
import { DeclarationAddress } from '../../types/DeclarationAddress';

class DeclarationAddresssService {
  async getAll(): Promise<DeclarationAddress[]> {
    const result = await axios.get('/declaration-addresss');
    return result.data;
  }

  async get(id: string): Promise<DeclarationAddress> {
    const result = await axios.get(`/declaration-addresss/${id}`);
    return result.data;
  }

  async import(file) {
    let formData = new FormData();
    formData.append('file', file);
    return axios.post(`/declaration-addresss/csv`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

const declarationAddresssService = new DeclarationAddresssService();
export default declarationAddresssService;

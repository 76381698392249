import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import propertiesService from '../../service/api/PropertiesService';
import wasteGroupsService from '../../service/api/WasteGroupsService';
import { Property } from '../../types/Property';
import { WasteTypeDetails } from '../../types/WasteType';
import { Receptions } from './Receptions';
import UsedLimits from './UsedLimits';
import { WasteGroup } from '../../types/WasteGroup';
import PropertyDetailsSection from './PropertyDetailsSection';

export const PropertyDetailsPage = () => {
  const { id } = useParams<any>();
  const [property, setProperty] = useState<Property>(null);
  const [wasteTypes, setWasteTypes] = useState<WasteTypeDetails[]>([]);
  const [wasteGroups, setWasteGroups] = useState<WasteGroup[]>(null);

  const { search } = useLocation<any>();
  const params = new URLSearchParams(search);

  const loadData = async (id: string) => {
    const propertyResponse = await propertiesService.get(id);
    setProperty(propertyResponse);

    setWasteTypes(await propertiesService.getSummary(id));
    setWasteGroups(await wasteGroupsService.getAll());
  };

  useEffect(() => {
    loadData(id);
  }, [id]);

  return (
    <>
      {property && <PropertyDetailsSection property={property}></PropertyDetailsSection>}
      {wasteTypes && wasteGroups && <UsedLimits wasteTypes={wasteTypes} wasteGroups={wasteGroups}></UsedLimits>}
      <Receptions
        propertyId={id}
        hasArrears={property?.arrearExists || !property?.declarationExists}
        wasteTypes={wasteTypes}
        wasteGroups={wasteGroups}
        cardNumber={params.get('card')}
        onSave={() => loadData(id)}
      ></Receptions>
    </>
  );
};

import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';

import declarationAddresssService from '../../service/api/DeclarationAddresssService';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';
import { DeclarationAddress } from '../../types/DeclarationAddress';
import { FileUpload } from 'primereact/fileupload';

export const DeclarationAddressManagement = () => {
  const toast = useRef<any>();

  const [globalFilter, setGlobalFilter] = useState('');
  const [loading, setLoading] = useState(true);

  const [declarationAddresss, setDeclarationAddresss] = useState<DeclarationAddress[]>(null);

  const [resultDialogVisible, setResultDialogVisible] = useState(false);

  const [importErrors, setImportErrors] = useState([]);

  const { t } = useTranslation();
  const fileUploader = useRef(null);

  const loadData = () => {
    setLoading(true);
    Promise.all([declarationAddresssService.getAll()]).then((response) => {
      const _declarationAddresss = response[0];
      setDeclarationAddresss(_declarationAddresss);
      setLoading(false);
    });
  };

  const importData = async (event) => {
    const [f] = event.files;
    await declarationAddresssService
      .import(f)
      .then((result) => {
        const errors = result?.data?.errors;
        if (errors?.length > 0) {
          setImportErrors(errors);
          setResultDialogVisible(true);
        } else {
          toast.current.show({
            severity: 'success',
            summary: t('common.dataImportSuccess'),
            life: 4000,
          });
        }
      })
      .catch(() => {
        toast.current.show({
          severity: 'error',
          summary: t('common.dataImportError'),
          detail: t('common.dataImportErrorDetails'),
          life: 4000,
        });
      })
      .finally(() => {
        loadData();
      });
    fileUploader.current.clear();
  };

  useEffect(() => {
    loadData();
  }, []);

  const declarationAddresssTableHeader = (
    <div className="table-header">
      {t('declarationAddresss.header')}
      <span className="flex ">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder={t('common.search')}
          />
        </span>
        <>
          <FileUpload
            ref={fileUploader}
            mode="basic"
            accept=".csv"
            maxFileSize={1000000}
            className="ml-2"
            customUpload
            auto
            uploadHandler={importData}
            chooseOptions={{
              label: t('common.import'),
              icon: 'pi pi-fw pi-download',
            }}
          />
        </>
      </span>
    </div>
  );

  const hideResultDialog = () => {
    setResultDialogVisible(false);
  };

  const resultDialogFooter = (
    <>
      <Button
        label={t('common.close')}
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => hideResultDialog()}
      />
    </>
  );

  const bodyTemplate = (data: DeclarationAddress, props: ColumnBodyOptions) => {
    return <>{data[props.field]}</>;
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <DataTable
            value={declarationAddresss}
            paginator
            className="p-datatable-customers"
            rows={10}
            dataKey="id"
            rowHover
            globalFilter={globalFilter}
            emptyMessage={t('declarationAddresss.empty_message')}
            loading={loading}
            header={declarationAddresssTableHeader}
          >
            <Column
              field="originalAddress"
              header={t('declarationAddresss.original_address')}
              sortable
              body={bodyTemplate}
            ></Column>
            <Column field="street" header={t('declarationAddresss.street')} sortable body={bodyTemplate}></Column>
            <Column
              field="houseNumberRangeStart"
              header={t('declarationAddresss.house_number_range_start')}
              sortable
              body={bodyTemplate}
            ></Column>
            <Column
              field="houseNumberRangeEnd"
              header={t('declarationAddresss.house_number_range_end')}
              sortable
              body={bodyTemplate}
            ></Column>
            <Column
              field="houseNumberSuffixStart"
              header={t('declarationAddresss.house_number_suffix_start')}
              sortable
              body={bodyTemplate}
            ></Column>
            <Column
              field="houseNumberSuffixEnd"
              header={t('declarationAddresss.house_number_suffix_end')}
              sortable
              body={bodyTemplate}
            ></Column>
            <Column
              field="apartmentNumberRangeStart"
              header={t('declarationAddresss.apartment_number_range_start')}
              sortable
              body={bodyTemplate}
            ></Column>
          </DataTable>
          <Dialog
            visible={resultDialogVisible}
            className="p-fluid"
            header={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                {importErrors?.length ? t('common.errorOnAddresses') : t('common.success')}
              </span>
            }
            modal
            footer={resultDialogFooter}
            onHide={hideResultDialog}
            breakpoints={{ '896px': '90vw' }}
            style={{ minWidth: '550px' }}
          >
            <div className="confirmation-content">
              {importErrors?.map((e) => (
                <div key={e}>{e}</div>
              ))}
            </div>
          </Dialog>
        </div>
      </div>

      <Toast ref={toast} />
    </div>
  );
};

import axios from 'axios';
import { ArrearAddress } from '../../types/ArrearAddress';

class ArrearAddresssService {
  async getAll(): Promise<ArrearAddress[]> {
    const result = await axios.get('/arrear-addresss');
    return result.data;
  }

  async get(id: string): Promise<ArrearAddress> {
    const result = await axios.get(`/arrear-addresss/${id}`);
    return result.data;
  }

  async import(file) {
    let formData = new FormData();
    formData.append('file', file);
    return axios.post(`/arrear-addresss/csv`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

const arrearAddresssService = new ArrearAddresssService();
export default arrearAddresssService;
